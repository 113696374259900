<template>
    <div>
        <div class="zx-box" v-if="Object.keys(this.date.sportsman).length === 0">
            <img class="zw" src="../../../assets/image/333.png" alt="">
            <div class="zwxx">暂无信息</div>
        </div>
        <div class="ydy-box" style="width: auto;" v-else>
            <div class="ydy ydys" v-for="(item, index) in this.date.sportsman.sportsList" :key="index">
                <div class="name-box">
                    <div class="ydyxian"></div>
                    <div class="name">{{ item }}</div>
                </div>
                <div class="ydybut">
                    <div class="ydybutname" @click="getentry(item)">参赛记录</div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="90%" :before-close="handleClose" top="5vh">
            <table border="1" class="table-xj" v-for="(item, index) in tableData3" :key="index">
                <tr>
                    <td class="dialogtitle">项目</td>
                    <td class="content">{{ item.sports }}</td>
                </tr>
                <tr>
                    <td class="dialogtitle">赛事名称</td>
                    <td class="content">{{ item.matchName }}</td>
                </tr>
                <tr>
                    <td class="dialogtitle">赛事时间</td>
                    <td class="content">
                        {{ item.matchDateStart }} 至 {{ item.matchDateEnd }}
                    </td>
                </tr>
                <tr>
                    <td class="dialogtitle">地点</td>
                    <td class="content">{{ item.matchAddressStadium }}</td>
                </tr>
                <tr>
                    <td class="dialogtitle">组别</td>
                    <td class="content">{{ item.gameGroup }}</td>
                </tr>
                <tr>
                    <td class="dialogtitle">小项</td>
                    <td class="content">{{ item.event }}</td>
                </tr>
                <tr>
                    <td class="dialogtitle">赛次（轮次）</td>
                    <td class="content">{{ item.games }}</td>
                </tr>
                <tr>
                    <td class="dialogtitle">代表队</td>
                    <td class="content">{{ item.delegation }}</td>
                </tr>
                <tr>
                    <td class="dialogtitle">姓名</td>
                    <td class="content">{{ item.names }}</td>
                </tr>
                <tr>
                    <td class="dialogtitle">成绩</td>
                    <td class="content">{{ item.grade }}</td>
                </tr>
                <tr>
                    <td class="dialogtitle">名次</td>
                    <td class="content">{{ item.ranking }}</td>
                </tr>
                <tr>
                    <td class="dialogtitle">备注</td>
                    <td class="content">{{ item.remarks }}</td>
                </tr>
            </table>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            date: {
                referee: {
                    certificateList: [],
                    enforcementList: [],
                    promotionList: []
                },
                user: {},
                sportsman: {}
            },
            dialogVisible: false,
            tableData3: [],
        }
    },
    created(){
        this.getUserCardInfo()
    },
    methods:{
        getUserCardInfo() {
            this.$api.getUserCardInfo({ userId: this.$store.state.userid }).then(res => {
                this.date = res.data.data.result
            })
        }, 
        handleClose() {
            this.dialogVisible = false
        },
        getentry(item) {
            this.dialogVisible = true
            let data = JSON.parse(JSON.stringify(this.date.sportsman))
            data.sports = item
            this.$api.getSportsmanScore(data).then(res => {
                res.data.data.result.forEach(item => {
                    item.names = this.$decrypt(item.names || '')
                    item.matchAddressStadium = item.addresses.map(items => {
                        return items.matchAddressStadium
                    }).join('、')
                })
                this.tableData3 = res.data.data.result
            })
        },
    }
}
</script>

<style scoped lang="scss">
.zx-box {
    margin-top: 40PX;
    display: flex;
    flex-direction: column;
    align-items: center;

    .zw {
        width: 257PX;
        height: 162PX;
    }

    .zwxx {
        font-family: SourceHanSansCN;
        font-weight: 500;
        font-size: 15PX;
        color: #989898;
        margin-top: 27PX;
        margin-bottom: 40PX;
    }
}
.ydy-box {
    margin: 47PX 25PX 0 25PX;
    display: flex;
    flex-wrap: wrap;


    .ydy {
        width: 48%;
        height: 130PX;
        background: url('../../../assets/image/555.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: 10PX;
        margin-bottom: 20PX;

        .name-box {
            display: flex;
            align-items: center;
            margin-left: 26PX;
            padding-top: 14PX;

            .ydyxian {
                width: 4PX;
                height: 16PX;
                background: #417BC8;
                border-radius: 3PX;
            }

            .name {
                font-family: SourceHanSansCN;
                font-weight: bold;
                font-size: 15PX;
                color: #408BFF;
                line-height: 36PX;
                background: linear-gradient(150deg, #336EBC 0.146484375%, #81B7FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-left: 10PX;
            }
        }

        .ydybut {
            width: 109PX;
            height: 26PX;
            background: url('../../../assets/image/444.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            margin-top: 33PX;
            margin-left: 25%;
            cursor: pointer;

            .ydybutname {
                font-family: SourceHanSansCN;
                font-weight: 500;
                font-size: 13PX;
                color: #629AE4;
                margin-left: 35PX;
            }
        }
    }

    .ydy:nth-child(2n) {
        margin-right: 0;
    }
}
.table {
    margin-top: 40PX;
    margin-left: 23PX;
    margin-right: 23PX;
}

.table ::v-deep .el-table thead {
    color: #fff;
    font-size: 16PX;
    font-weight: 500;
    font-size: 11PX;
}

.table ::v-deep .el-table__header-wrapper {
    border-top-left-radius: 10PX;
    border-top-right-radius: 10PX;
}

.table ::v-deep .el-table {
    border-top-left-radius: 10PX;
    border-top-right-radius: 10PX;
}

.table ::v-deep .el-table th.el-table__cell.is-leaf {
    border-bottom: 1PX solid #333333;
    border-right: 1PX solid #333333;
}

.table ::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
    border-color: #333333;
}

.table ::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
    background-color: #333333;
}

.table-xj {
    width: 100%;
    margin-bottom: 20PX;

    tr {
        height: 30PX;
    }

    .dialogtitle {
        color: #fff;
        background: #0D6DF5;
        width: 90PX;
        text-align: center;
    }

    .content {
        text-align: center;
        padding: 0 5PX;
        color: #333333;
        word-break: break-all;
        overflow-wrap: break-word;
    }
}
</style>